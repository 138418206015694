(function () {
    'use strict';

    angular.module('common')
        .config(function (blockUIConfig) {
            blockUIConfig.delay = 500; // x2 of default

            blockUIConfig.requestFilter = function (config) {

                var filterResponse;

                var filters = [
                    ignoreAPIFilter,

                    /*authenticateFilter,
                    getAerosAppInfoFilter,
                    getProjectsFilter,
                    getToolsFilter,
                    getLimitsFilter,
                    toggleToolStatusFilter,
                    getReportsFilter,
                    importSorFiles,*/

                    defaultMessageByMethodFilter];

                for (var i in filters) {
                    filterResponse = filters[i](config);

                    if (filterResponse !== undefined) {
                        return filterResponse;
                    }
                }

            };

        });

    function authenticateFilter(config) {

        if (config.url.indexOf('authenticateProfile') >= 0) {
            return "Authenticate...";
        }
    }

    function defaultMessageByMethodFilter() {
        return "Please Wait...";
    }

    function getAerosAppInfoFilter(config) {
        if (config.url.indexOf('aeRosAppInfo') >= 0) {
            return "Getting app info...";
        }
    }

    function toggleToolStatusFilter(config) {
        if ((config.url.indexOf('certification/tools') >= 0 ||
            config.url.indexOf('olts/tools') >= 0 ||
            config.url.indexOf('inspection/tools') >= 0 )
            && config.method === 'DELETE') {
            return "Toggling status...";
        }
    }

    function getProjectsFilter(config) {
        if (atTheEnd(config.url, '/projects') && config.method === 'GET') {
            return "Loading projects...";
        }
    }

    function getToolsFilter(config) {
        if (atTheEnd(config.url, '/tools') && config.method === 'GET') {
            return "Loading tools...";
        }
    }

    function getLimitsFilter(config) {
        if (atTheEnd(config.url, '/tools/limits') && config.method === 'GET') {
            return "Loading limits...";
        }
    }

    function getReportsFilter(config) {
        //ignore reports refresh
        if (config.url.indexOf('/projectreport') >= 0) {
            return "Updating reports list...";
        }
    }

    function importSorFiles(config) {
        if (atTheEnd(config.url, '/sor/file/import/sorFile') && config.method === 'POST') {
            return "Importing SOR Files...";
        }
    }

    function ignoreAPIFilter(config) {
        if (config.url.indexOf('changes?since') >= 0) {
            return false;
        }

        // ignore report generation statuses
        if (atTheEnd(config.url, '/ready') && config.method === 'GET') {
            return false;
        }
    }

    function atTheEnd(str, substr) {
        return str.indexOf(substr) === str.length - substr.length;
    }

})();
